/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useContext, useRef, useState } from 'react';
import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
// import Avatar like this until UICOMP-10500
import Avatar from '@concur/nui-widgets/lib/Avatar/Avatar';
import Icon from '@concur/nui-widgets/Icon';
import initials from 'initials';
import Spinner from '@concur/nui-widgets/Spinner';
import { withFormatter } from '@concur/nui-intl-runtime';
import {
    compose, lowerCase, withErrorBoundary,
} from '@concur/core-ui-shell';
import initialsSupported from '../utils/initialsSupported';
import useModifiedHelpMenu from '../utils/useModifiedHelpMenu';
import LanguageDropdown from './_LanguageDropdown';
import Menu from './_Menu';
import MenuItem from './_MenuItem';
import MenuItemToggle from './_MenuItemToggle';
import Navigation from './_Navigation';
import ProductMenu from './_ProductMenu';
import ShellbarNavBar from './_ShellbarNavBar';
import AppHeaderContext from './_AppHeaderContext';
import UserProfileMenuFiori from '../UserProfileMenu/UserProfileMenuFiori';
import Logo from '../Logo/Logo';
import { getProfileDisplayText } from '../UserProfileMenu/_ProfileIdentifier';
import styles from './Shellbar.css';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import HelpSvgIcon from './_HelpSvgIcon';
import DigitalAssistantMenuItem from './_DigitalAssistantMenuItem';
import HelpPortalMenuItem from './_HelpPortalMenuItem';
import TranslationToolMenuItem from './_TranslationToolMenuItem';
import NavLogo from '../Logo/_NavLogo';

const CSS_BLOCK = 'sapcnqr-shellbar';

const getActiveMenuItem = (menuData) => {
    const {
        homeMenuItem,
        applicationMenuItems,
        profileMenuItem,
        adminMenuItem,
        helpMenuItem,
        linksMenuItem,
        testDriveMenuItem,
        subscribeMenuItem,
    } = menuData;

    // create array of all menu items that could be active
    const topLevelMenuItems = [
        homeMenuItem,
        testDriveMenuItem,
        ...(applicationMenuItems || []),
        adminMenuItem,
        linksMenuItem,
        helpMenuItem,
        profileMenuItem,
        subscribeMenuItem,
    ];

    const matchingMenuItems = topLevelMenuItems.filter((item) => item && item.isActive);

    return (matchingMenuItems.length > 0 ? matchingMenuItems[0] : homeMenuItem);
};

const getAdjustedMenuData = (menuData, formatter) => {
    const {
        helpMenuItem,
        supportMenuItem,
        ...otherMenuData
    } = menuData || {};

    const helpMenuItemSubItems = [
        ...helpMenuItem?.subItems || [],
    ];

    if (supportMenuItem) {
        helpMenuItemSubItems.push({
            ...supportMenuItem,
            // when moving support into the help menu, it changes label and id values
            name: formatter.formattedMessage({ id: 'CoreUI.contactSupport' }),
            id: 'ContactSupport',
        });
    }

    const adjustedMenuData = {
        ...otherMenuData,
        helpMenuItem: {
            ...helpMenuItem,
            subItems: helpMenuItemSubItems,
        },
    };

    return adjustedMenuData;
};

const Shellbar = (props) => {
    const {
        actingAs,
        className,
        formatter,
        // eslint-disable-next-line no-unused-vars
        isGov,
        isLoggedIn,
        isRetiredBrand,
        lang,
        logoutUser,
        menuData,
        onEndSession,
        onStartSession,
        showLanguageDropdown,
    } = props;

    const adjustedMenuData = getAdjustedMenuData(menuData, formatter);

    const {
        homeMenuItem,
        profileMenuItem,
        helpMenuItem,
        pageMenuItems,
    } = adjustedMenuData;

    const classnames = classnamesBind.bind(styles);

    const {
        isLocalizationUser,
    } = useContext(AppHeaderContext) || {};
    const { employeeDisplayName } = useContext(AppHeaderContext) || {};

    const [showSpinner, setShowSpinner] = useState(false);
    const [helpPortalLoaded, setHelpPortalLoaded] = useState(false);
    const [translationToolLoaded, setTranslationToolLoaded] = useState(false);

    const classes = classnames(CSS_BLOCK, className);

    const primaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--primary`,
    );

    const secondaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--secondary`,
    );

    const homeIconClasses = classnames(
        `${CSS_BLOCK}__home-icon`,
        {
            [`${CSS_BLOCK}__home-icon--retired`]: isRetiredBrand,
        },
    );

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);

    const generateProfileButton = () => {
        const profileDisplayText = getProfileDisplayText({
            actingAs,
            formatter,
            employeeDisplayName,
            shortNames: true,
        });

        if (isActingForOthers) {
            return (
                <>
                    <Icon
                        ariaHidden
                        className={classnames(`${CSS_BLOCK}__acting-as-icon`)}
                        iconName="user-admin"
                    />
                    <div className={classnames(`${CSS_BLOCK}__profile-description`)}>
                        {profileDisplayText}
                    </div>
                </>
            );
        }

        if (initialsSupported(lang)) {
            const avatarInitials = typeof employeeDisplayName === 'string'
                ? initials.parse(employeeDisplayName).initials
                : undefined;

            if (avatarInitials) {
                return (
                    <Avatar
                        className={classnames(`${CSS_BLOCK}__avatar`)}
                        ariaLabel={formatter.formattedMessage({ id: 'CoreUI.profile.profile' })}
                        accentColor={6}
                        data-test="user-icon-avatar-shellbar"
                        iconName="user"
                        size="xs"
                    >
                        {avatarInitials}
                    </Avatar>
                );
            }
        }

        return (
            <Avatar
                className={classnames(`${CSS_BLOCK}__avatar`)}
                ariaLabel={formatter.formattedMessage({ id: 'CoreUI.profile.profile' })}
                accentColor={6}
                data-test="user-icon-avatar-shellbar"
                iconName="user"
                size="xs"
            />
        );
    };

    const activeMenuItem = getActiveMenuItem(adjustedMenuData) || {};

    const handleStartLoading = () => {
        setShowSpinner(true);
    };

    const handleEndLoading = () => {
        setShowSpinner(false);
    };

    useModifiedHelpMenu({
        menuData: adjustedMenuData,
        translationToolLoaded,
        helpPortalLoaded,
    });

    const homeLogoDescriptionId = `logo-description-${homeMenuItem?.id || 'home'}`.trim();

    const navLogo = (
        <NavLogo
            styles={styles}
            cssBlock={CSS_BLOCK}
            className={homeIconClasses}
            isRetiredBrand={isRetiredBrand}
            descriptionId={homeLogoDescriptionId}
        />
    );

    const profileQuickHelpRef = useRef();
    const profileUserDropdownRef = useRef();

    const shellbarProfileMenuId = 'shellbarProfileMenu';

    const profileOverlayProps = {
        onClickOutside: (event) => {
            if (profileQuickHelpRef?.current?.contains?.(event?.target)
                || profileUserDropdownRef?.current?.contains?.(event?.target)
                || (event?.target && event?.target?.closest?.(`#${shellbarProfileMenuId}`) !== null)
            ) {
                return true;
            }

            return false;
        },
        popperContentProps: {
            className: classnames(`${CSS_BLOCK}__overlay-profile-wrapper`),
        },
        id: shellbarProfileMenuId,
        className: classnames(
            `${CSS_BLOCK}__overlay-profile`,
            {
                [`${CSS_BLOCK}__overlay-profile--spinner-visible`]: showSpinner,
            },
        ),
    };

    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={UserProfileMenu.cssBlock}
            correlationId={errorId}
        />
    );

    return (
        <div className={classes} role="banner">
            {isLoggedIn && (
                <a
                    className={classnames(`${CSS_BLOCK}__screen-reader-only`)}
                    href="#cnqr-app-content"
                >
                    {formatter.formattedMessage({ id: 'CoreUI.skipNavigation' })}
                </a>
            )}
            {isLoggedIn ? (
                <Navigation
                    ariaLabel={formatter.formattedMessage({ id: 'CoreUI.primaryMenuAriaLabel' })}
                    className={primaryNavClasses}
                >
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--primary-home`)}
                        id="home"
                    >
                        <MenuItemToggle
                            buttonAriaLabel={activeMenuItem.name}
                            styles={styles}
                            cssBlock={CSS_BLOCK}
                            buttonClassName={classnames(`${CSS_BLOCK}__branded-product-menu-button`)}
                            buttonProps={{
                                'data-test': 'sapcnqr-shellbar-product-menu',
                                'aria-describedby': homeLogoDescriptionId,
                            }}
                            tooltipMessage={formatter.formattedMessage({ id: 'CoreUI.applicationMenu' })}
                            overlayProps={{
                                innerDialogClassName: classnames(`${CSS_BLOCK}__product-menu-overlay`),
                                popperContentProps: {
                                    className: classnames(`${CSS_BLOCK}__product-menu`),
                                },
                            }}
                            content={navLogo}
                            iconOnly
                        >
                            <ProductMenu isFioriProductMenu menuData={adjustedMenuData} />
                        </MenuItemToggle>
                    </Menu>
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--primary`)}
                        id="primary"
                    >
                        {homeMenuItem && (
                            <MenuItem
                                {...homeMenuItem}
                                tooltipMessage={formatter.formattedMessage({ id: 'CoreUI.navigateToHome' })}
                                styles={styles}
                                cssBlock={CSS_BLOCK}
                                className={classnames(`${CSS_BLOCK}__listitem--home`)}
                                isActive={false} // home menu item is never shown as active
                                key="dashboard"
                                labelId={`${homeMenuItem?.labelId || ''} ${homeLogoDescriptionId}`.trim()}
                            >
                                {navLogo}
                            </MenuItem>
                        )}
                        <MenuItemToggle
                            buttonAriaLabel={activeMenuItem.name}
                            styles={styles}
                            cssBlock={CSS_BLOCK}
                            buttonClassName={classnames(`${CSS_BLOCK}__button--product`)}
                            buttonProps={{
                                'data-test': 'sapcnqr-shellbar-product-menu',
                            }}
                            tooltipMessage={formatter.formattedMessage({ id: 'CoreUI.applicationMenu' })}
                            overlayProps={{
                                innerDialogClassName: classnames(`${CSS_BLOCK}__product-menu-overlay`),
                            }}
                            content={activeMenuItem.name}
                        >
                            <ProductMenu isFioriProductMenu menuData={adjustedMenuData} />
                        </MenuItemToggle>
                    </Menu>
                    <div className={classnames(`${CSS_BLOCK}__right`)}>
                        {(isLocalizationUser || helpMenuItem || profileMenuItem) && (
                            <Menu
                                className={classnames(`${CSS_BLOCK}__menu`, `${CSS_BLOCK}__menu--utility`)}
                                id="utility"
                                isTopLevelMenu
                            >
                                {isLocalizationUser
                                    && (
                                        <TranslationToolMenuItem
                                            onLoad={setTranslationToolLoaded}
                                        />
                                    )}
                                <DigitalAssistantMenuItem />
                                <HelpPortalMenuItem
                                    menuData={adjustedMenuData}
                                    onLoad={setHelpPortalLoaded}
                                />
                                {helpMenuItem && !helpPortalLoaded && (
                                    <MenuItemToggle
                                        buttonAriaLabel={helpMenuItem.name}
                                        buttonProps={{ 'data-test': helpMenuItem.id ? `menu__listitem-${lowerCase(helpMenuItem.id)}` : null }}
                                        styles={styles}
                                        cssBlock={CSS_BLOCK}
                                        content={(
                                            <HelpSvgIcon
                                                className={classnames(`${CSS_BLOCK}__svg-icon`)}
                                            />
                                        )}
                                        hideCaret
                                        iconOnly
                                        popperPlacement="bottom-end"
                                    >
                                        <Menu
                                            className={classnames(`${CSS_BLOCK}__submenu`, `${CSS_BLOCK}__submenu--toggle`)}
                                            id={helpMenuItem.id}
                                        >
                                            {helpMenuItem.subItems.map((item, index) => (
                                                <MenuItem
                                                    {...item}
                                                    styles={styles}
                                                    cssBlock={CSS_BLOCK}
                                                    key={`help${index}`}
                                                />
                                            ))}
                                        </Menu>
                                    </MenuItemToggle>
                                )}
                                {profileMenuItem && (
                                    <>
                                        <MenuItemToggle
                                            buttonClassName={isActingForOthers
                                                ? classnames(`${CSS_BLOCK}__button--acting-as`)
                                                : classnames(`${CSS_BLOCK}__button--avatar`)}
                                            buttonProps={{ 'data-test': profileMenuItem.id ? `menu-${lowerCase(profileMenuItem.id)}` : null }}
                                            overlayProps={profileOverlayProps}
                                            styles={styles}
                                            cssBlock={CSS_BLOCK}
                                            content={generateProfileButton()}
                                            tooltipMessage={formatter.formattedMessage({ id: 'CoreUI.profile.openProfileMenu' })}
                                            hideCaret
                                            iconOnly={!isActingForOthers}
                                            popperPlacement="bottom-end"
                                        >
                                            <UserProfileMenuFiori
                                                styles={styles}
                                                actingAs={actingAs}
                                                logoutUser={logoutUser}
                                                onEndLoading={handleEndLoading}
                                                onEndSession={onEndSession}
                                                onStartLoading={handleStartLoading}
                                                onStartSession={onStartSession}
                                                profileUrl={profileMenuItem.url}
                                                errorComp={fallbackComp}
                                                actingAsQuickHelpRef={profileQuickHelpRef}
                                                actingAsUserDropdownRef={profileUserDropdownRef}
                                            />
                                        </MenuItemToggle>
                                        <Spinner
                                            message={formatter.formattedMessage({ id: 'CoreUI.switchingActingAsView' })}
                                            type="fullScreen"
                                            visible={showSpinner}
                                        />
                                    </>
                                )}
                            </Menu>
                        )}
                    </div>
                </Navigation>
            ) : (
                <span className={classnames(`${CSS_BLOCK}__nav`, {
                    [`${CSS_BLOCK}__nav--signin`]: !isLoggedIn,
                })}
                >
                    <span className={classnames(`${CSS_BLOCK}__brand`)}>
                        <Logo
                            cssBlock={CSS_BLOCK}
                            styles={styles}
                            className={homeIconClasses}
                            isRetiredBrand={isRetiredBrand}
                            hideTitle
                        />
                    </span>
                    {showLanguageDropdown && (
                        <LanguageDropdown
                            styles={styles}
                            cssBlock={CSS_BLOCK}
                        />
                    )}
                </span>
            )}
            {isLoggedIn && pageMenuItems && pageMenuItems.length > 0 && (
                <ShellbarNavBar
                    className={secondaryNavClasses}
                    menuItems={pageMenuItems}
                />
            )}
        </div>
    );
};

Shellbar.displayName = 'Shellbar';

Shellbar.cssBlock = CSS_BLOCK;

Shellbar.defaultProps = {
    actingAs: {},
};

Shellbar.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        otherUserName: PropTypes.string,
    }),
    menuData: PropTypes.shape({
        homeMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        applicationMenuItems: PropTypes.arrayOf(
            PropTypes.shape(MenuItem.apiPropTypes),
        ),
        profileMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        adminMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        helpMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        linksMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        testDriveMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        localizationToolMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        subscribeMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        supportMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        pageMenuItems: PropTypes.arrayOf(
            PropTypes.shape(MenuItem.apiPropTypes),
        ),
        noMenuDataFallback: PropTypes.node,
    }),
    employeeName: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        preferredName: PropTypes.string,
    }),
    className: PropTypes.string,
    lang: PropTypes.string,
    // fullName is being replaced by employeeName, but fullName needs to stay
    // for a period of time for backwards compatibility
    fullName: PropTypes.string,
    isGov: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    logoutUser: PropTypes.func,
    onEndSession: PropTypes.func,
    onStartSession: PropTypes.func,
    showLanguageDropdown: PropTypes.bool,
    stretchContent: PropTypes.bool,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
};

export default compose(
    withFormatter,
    withErrorBoundary,
)(Shellbar);
