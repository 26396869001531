import React from 'react';
import classnamesBind from 'classnames/bind';
import useEmbeddedApp from '../utils/useEmbeddedApp';
import styles from './Shellbar.css';

const CSS_BLOCK = 'sapcnqr-shellbar';

const AppContainerMenuItem = ({
    fireOnContainerLoaded,
    listenForEmbeddedAppLoaded,
    onLoad,
    fireOnContainerReady,
    appData,
    containerId,
}) => {
    const {
        loaded,
        containerRef,
        attachToRef,
    } = useEmbeddedApp(
        {
            fireOnContainerLoaded,
            listenForEmbeddedAppLoaded,
            onLoad,
            fireOnContainerReady,
            appData,
        },
    );

    const classnames = classnamesBind.bind(styles);

    return (
        <li
            aria-hidden={!loaded}
            ref={containerRef}
            className={classnames(`${CSS_BLOCK}__listitem`)}
        >
            {loaded ? (
                <span
                    className={classnames(`${CSS_BLOCK}__app-container`)}
                    ref={attachToRef}
                    id={containerId}
                />
            ) : null}
        </li>
    );
};

AppContainerMenuItem.displayName = 'AppContainerMenuItem';

export default AppContainerMenuItem;
